import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TestRefNos } from '@app/_helpers/constants';
import { pagedVClaim } from '@app/_models/paged-vclaim';
import { environment } from 'src/_environments/environment';


@Injectable({
  providedIn: 'root'
})

export class FunctionsService {
  constructor(private datePipe: DatePipe) {
  }

  excludeTestClaims(arr: any[], columnName:string): any[] {
    if (environment.production)
      return arr.filter(x=> !TestRefNos.includes(x[columnName]));
    else
      return arr;
  }

  excludeClaims(obj: pagedVClaim ): pagedVClaim   {
    if (environment.production){
      let objs:any[]=obj.records;
      let count:number=obj.totalCount;
      let objVclaim:pagedVClaim={totalCount:obj.totalCount, records:objs};
      objVclaim.records=objs.filter(y=>!TestRefNos.includes(y['refNo']));
      objVclaim.totalCount= objVclaim.records.length;
      return objVclaim;
    }
    else
      return obj;
  }

  getUniqueMonthYearDates(dates: Date[]): Date[] {
    const uniqueDates = new Set<string>();
  
    dates.forEach(date => {
      const dt = new Date(date); 
      const monthYear = `${dt.getFullYear()}-${dt.getMonth() + 1}`;
      uniqueDates.add(monthYear);
    });
  
    const result: Date[] = [];
    uniqueDates.forEach(monthYear => {
      const [year, month] = monthYear.split('-').map(Number);
      result.push(new Date(year, month - 1, 1)); 
    });
  
    return result;
  }

  //Get the time value of 1955-05-05 for comparison of dates
  getTime5555() {
    let retVal: Number;
    retVal = new Date("1955-05-05T00:00:00").getTime();
    return retVal;
  }

  //Get the time value of the input date for comparison of dates
  getTimeValue(inDateTime: Date | null) {
    let retVal: Number;
    if(inDateTime)
      retVal = new Date(inDateTime).getTime();
    else
      retVal = this.getTime5555();
    return retVal;
  }

  getNextBusinessDay() {
    var moment = require('moment-business-days');
    var nextBusinessDate = moment().businessAdd(1);
    return nextBusinessDate;
  }

  //Return either the Finalised Date, Blank Value or "N/A" depending on the input dates
  getDateClosed(bldCompletion: Date | null, ctsCompletion: Date | null) {
    var dateBld: Date | null = null;
    var dateCts: Date | null = null;

    if (bldCompletion != null)
      dateBld = new Date(bldCompletion);

    if (ctsCompletion != null)
      dateCts = new Date(ctsCompletion);

    if ((bldCompletion == null || dateBld?.getTime() == this.getTime5555()) && (ctsCompletion == null || dateCts?.getTime() == this.getTime5555())) {
      {
        if (bldCompletion == null || ctsCompletion == null)
          return "";
        else
          return "N/A";
      }
    }
    else {
      let dateClosed = new Date();
      if (dateBld == null || dateCts == null) {
        return "";
      }
      else if (dateBld) {
        if(bldCompletion)
          dateClosed = new Date(bldCompletion);
      }
      else if (dateCts != null) {
        if(ctsCompletion)
          dateClosed = new Date(ctsCompletion);
      }
      else {
        if (dateBld > dateCts)
        if(bldCompletion)
          dateClosed = new Date(bldCompletion);
        else
        if(ctsCompletion)
          dateClosed = new Date(ctsCompletion);
      }
      if (dateClosed.getTime() == this.getTime5555()) {
        return "N/A";
      }
      return dateClosed;
    }
  }

  datePickerValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = true;
      if (control.value) {
        const date: Date = control.value;
        const now = new Date();
        if (date.getDate() < now.setFullYear(now.getFullYear() - 2)) {
          forbidden = false;
        }
      }
      return forbidden ? { 'dateTooOld': true } : null;
    };
  }

  getDisplayName(nameString: string) {
    let result: string = nameString;
    if (nameString) {
      var nameArr = nameString.split(' ');
      let initial!: string;
      if (nameArr.length > 1)
        initial = nameArr.pop()?.charAt(0).toUpperCase()!;

      if (initial)
        result = nameArr[0] + ' ' + initial;
    }
    return result?.trim();

  }

  getCurrentTimestampFormatted()
  {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString();
  }
}