import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  user: User = new User;
  constructor(public accountService: AccountService, private alertService: AlertService) {

    // if (this.accountService.isLoggedIn()) {
    //   if (this.accountService.userValue.id != '') {
    //     this.user = this.accountService.userValue;
    //   }
    // }
  }
}