import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Statistics } from '@app/_models/statistics';
import { VClaimReport } from '@app/_models/vClaimReport';
import { YearlyStatistics } from '@app/_models/yearlyStatistics';
import { AccountService, AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  months = new FormControl('');
  years = new FormControl();
  selectedTabIndex: number = 0;
  today= new Date();
  currentMonth!:Date;
  top3Loss:string='';
  statisticsYears:number[]=[];
  selectedYear!: number;
  monthsYears:Date[]=[];
  selectedMonthsYears!: Date;
  displayedColumnsYearly: string[] = 
  [
    'sla',
    'mk3Target',
    'monthlyAverage',
  ];
  dataSourceYearlyStatistics!:MatTableDataSource<YearlyStatistics>;
  yearlyStatistics: any[] = [];

  displayedColumnsReport: string[] = [
    'claimNumber',
    'otherReferenceNumber',
    'propertyAddress',
    'state',
    'postCode',
    'claimType',
    'quantumOfClaim',
    'dateOfAppointment',
    'dateOfInitialCustomerContact',
    'dateOfSiteVisit',
    'dateOfFirstReport',
    'dateClosed',
    'statusWithPanels',
    'anticipatedCompletionDate'
  ];

  dataSourceClaimsReport = new MatTableDataSource<VClaimReport>();
  
  isLoading = false;
  activeLinkIndex = -1;

  sortColumn =
    localStorage.getItem('reportSortColumn') != null
      ? localStorage.getItem('reportSortColumn')
      : 'otherReferenceNumber';
  sortDirection =
    localStorage.getItem('reportSortDirection') != null
      ? localStorage.getItem('reportSortDirection')
      : 'desc';
  
  pageSizeControl = new FormControl(
    localStorage.getItem('pageSize')
      ? Number(localStorage.getItem('pageSize'))
      : 10
  );

  @ViewChild(MatSort) matSort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  async ngOnInit() {
    this.alertService.clear();
    this.months = new FormControl('');
    this.years = new FormControl('');
    this.today = new Date();
    await this.fillYears()
    this.currentMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    this.loadTop3Loss(5, this.today);
    this.selectedYear = this.today.getFullYear();
    this.getYearlyStatistics(this.selectedYear);
    this.fillMonths();

    this.years.valueChanges
    .subscribe(
      yearStr => {
        this.selectedYear = (Number)(yearStr!);
        this.loadTop3Loss(5, this.today, this.selectedYear);
        this.getYearlyStatistics(this.selectedYear);
      }
    );

    this.months.valueChanges
    .subscribe(
      dateStr => {
        const [monthName, year] = dateStr!.split(' ');
        this.selectedMonthsYears = new Date(`${monthName} 1, ${year}`);
        this.getClaimReport(this.selectedMonthsYears, 5);
      }
    );
  }

  onTabClick(event: any) {
    this.selectedTabIndex = event.index;
    this.alertService.clear();
    if(this.selectedTabIndex == 0)
      this.getYearlyStatistics(this.selectedYear);
    else {
      if (this.dataSourceClaimsReport.data.length > 0)
        this.refreshData();
      else
        this.getClaimReport(this.selectedMonthsYears);
    }
  }

  constructor(
    private restService: RestService,
    private route: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    public functionsService: FunctionsService,
    private alertService: AlertService,
    private datepipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {}

  setColumns(statistics:YearlyStatistics[]): void {
    if (statistics) {
      if (!this.displayedColumnsYearly.includes('january') &&  this.yearlyStatistics.some(x => x['january'] !== null))
        this.displayedColumnsYearly.push('january');
      else if (this.displayedColumnsYearly.includes('january') && this.yearlyStatistics.every(x => x['january'] == null))
          this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('january'), 1);
       
      if (!this.displayedColumnsYearly.includes('february') && this.today.getFullYear()>2024 && this.yearlyStatistics.some(x => x['february'] !== null))
        this.displayedColumnsYearly.push('february');
      else if (this.displayedColumnsYearly.includes('february') && this.yearlyStatistics.every(x => x['february'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('february'), 1);
       
      if (!this.displayedColumnsYearly.includes('march') && this.today.getFullYear()>2024 && this.yearlyStatistics.some(x => x['march'] !== null))
        this.displayedColumnsYearly.push('march');
      else if (this.displayedColumnsYearly.includes('march') && this.yearlyStatistics.every(x => x['march'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('march'), 1);
       
      if (!this.displayedColumnsYearly.includes('april') && this.yearlyStatistics.some(x => x['april'] !== null))
        this.displayedColumnsYearly.push('april');
      else if (this.displayedColumnsYearly.includes('april') && this.yearlyStatistics.every(x => x['april'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('april'), 1);
       
      if (!this.displayedColumnsYearly.includes('may') && this.yearlyStatistics.some(x => x['may'] !== null))
        this.displayedColumnsYearly.push('may');
      else if (this.displayedColumnsYearly.includes('may') && this.yearlyStatistics.every(x => x['may'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('may'), 1);

      if (!this.displayedColumnsYearly.includes('june') && this.yearlyStatistics.some(x => x['june'] !== null))
        this.displayedColumnsYearly.push('june');
      else if (this.displayedColumnsYearly.includes('june') && this.yearlyStatistics.every(x => x['june'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('june'), 1);

      if (!this.displayedColumnsYearly.includes('july') && this.yearlyStatistics.some(x => x['july'] !== null))
        this.displayedColumnsYearly.push('july');
      else if (this.displayedColumnsYearly.includes('july') && this.yearlyStatistics.every(x => x['july'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('july'), 1);

      if (!this.displayedColumnsYearly.includes('august') && this.yearlyStatistics.some(x => x['august'] !== null))
        this.displayedColumnsYearly.push('august');
      else if (this.displayedColumnsYearly.includes('august') && this.yearlyStatistics.every(x => x['august'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('august'), 1);

      if (!this.displayedColumnsYearly.includes('september') && this.yearlyStatistics.some(x => x['september'] !== null))
        this.displayedColumnsYearly.push('september');
      else if (this.displayedColumnsYearly.includes('september') && this.yearlyStatistics.every(x => x['september'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('september'), 1);

      if (!this.displayedColumnsYearly.includes('october') && this.yearlyStatistics.some(x => x['october'] !== null))
        this.displayedColumnsYearly.push('october');
      else if (this.displayedColumnsYearly.includes('october') && this.yearlyStatistics.every(x => x['october'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('october'), 1);

      if (!this.displayedColumnsYearly.includes('november') && this.yearlyStatistics.some(x => x['november'] !== null))
        this.displayedColumnsYearly.push('november');
      else if (this.displayedColumnsYearly.includes('november') && this.yearlyStatistics.every(x => x['november'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('november'), 1);

      if (!this.displayedColumnsYearly.includes('december') && this.yearlyStatistics.some(x => x['december'] !== null))
        this.displayedColumnsYearly.push('december');
      else if (this.displayedColumnsYearly.includes('december') && this.yearlyStatistics.every(x => x['december'] == null))
        this.displayedColumnsYearly.splice(this.displayedColumnsYearly.indexOf('december'), 1);
    }
  }

  async fillYears(){
    this.isLoading = true;
    const response = await this.restService.getStatisticsByClientID(5);
    response.subscribe(data => {
      this.statisticsYears = Array.from(new Set(data.records.map(x => new Date(x.createTime).getFullYear())));
      if (!this.statisticsYears.some(x=>x==this.today.getFullYear())){
        this.statisticsYears.push(this.today.getFullYear());
      }
      this.selectedYear = this.today.getFullYear();
      this.years.setValue(this.selectedYear.toString());
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

 async fillMonths(){
    this.isLoading = true;
    const observable = await this.restService.getEOMClaimReport(5);
    observable.subscribe(data => {
      this.monthsYears = this.functionsService.getUniqueMonthYearDates(data.map(y => y.createDate));
    });
    if (!this.monthsYears.some(x=>x.getFullYear()==this.today.getFullYear() && x.getMonth()==this.today.getMonth())){
      this.monthsYears.push(this.currentMonth);
    }
    this.selectedMonthsYears = this.currentMonth;
    this.months.setValue(this.datepipe.transform( this.currentMonth, 'MMMM yyyy'));
    
    this.isLoading = false;
  }

  async getClaimReport(months:Date, clientId: number = 5) {
    this.alertService.clear();
    this.isLoading = true;
    try {
      if (months.getFullYear()===this.today.getFullYear() && months.getMonth() === this.today.getMonth()){
        const response = await this.restService.getVClaimReport(clientId);
        if (response &&  Array.isArray(response)) {
          this.dataSourceClaimsReport= new MatTableDataSource(this.functionsService.excludeTestClaims(response,'otherReferenceNumber'));
        } else {
          console.error('Invalid response structure:', response); 
        } 
      }  
      else{
        const response = await this.restService.getEOMClaimReportByDate(5, months);
        if (response &&  Array.isArray(response)) {
          this.dataSourceClaimsReport= new MatTableDataSource(this.functionsService.excludeTestClaims(response,'otherReferenceNumber'));
        } else {
          console.error('Invalid response structure:', response);
        } 
      }
      
      if (
        this.pageSizeControl.value != null &&
        this.pageSizeControl.value > 100 &&
        this.dataSourceClaimsReport.data.length !=
          Number(localStorage.getItem('pageSize'))
      ) {
        localStorage.setItem(
          'pageSize',
          this.dataSourceClaimsReport.data.length.toString()
        );
      }
      if (!this.matSort ){
        this.matSort = new MatSort();
      }
      this.dataSourceClaimsReport.sort = this.matSort;
      this.dataSourceClaimsReport.paginator = this.paginator;
      this.matSort.sort({ id: this.sortColumn, start: this.sortDirection, disableClear: true } as MatSortable);
      this.isLoading = false;
    } catch (error) {
    console.error('Error fetching paged statistics:', error);
    }
      this.isLoading = false;
  }

  refreshData() {
    this.isLoading = true;
    const pageSize =  localStorage.getItem('pageSize') == null ? '10':localStorage.getItem('pageSize')
    localStorage.setItem('pageSize', pageSize!);
    this.paginator._changePageSize(Number(pageSize));
    this.dataSourceClaimsReport.sort = this.matSort;
    this.dataSourceClaimsReport.paginator = this.paginator;
    this.matSort.sort({ id: this.sortColumn, start: this.sortDirection, disableClear: true } as MatSortable);  
    this.isLoading = false;
  }

  loadTop3Loss(clientId: number, date: Date, year:number=this.today.getFullYear()) {
    this.restService.getTop3Loss(clientId, date, year).subscribe({
      next: (data: string) => {
        this.top3Loss = data;
      },
      error: (error) => {
        console.error('Error fetching top 3 loss:', error);
        this.top3Loss = 'Error fetching data';
      }
    });
  }
  
  async getYearlyStatistics(year:number): Promise<void> {
    this.alertService.clear();
    this.isLoading = true;
    //this.displayedColumnsYearly = this.headerColumnsYearly;
    try {
      if (year === this.today.getFullYear()){
        const response = await this.restService.getYearlyStatistics(5, new Date());
        if (response && response.records && Array.isArray(response.records)) {
          this.yearlyStatistics = response.records;
          this.setColumns(this.yearlyStatistics);
          if (year == 2024){
              this.yearlyStatistics.forEach(stat => {
                const values = [stat.april, stat.may, stat.june,stat.july, stat.august, stat.september,stat.october, stat.november, stat.december].filter(value => value !== null && value !== undefined);
                const sum = values.reduce((acc, value) => acc + (value as number), 0);
                stat.monthlyAverage = values.length > 0 ? parseFloat((sum / values.length).toFixed(2)) : null;
            });
          }
          this.dataSourceYearlyStatistics = new MatTableDataSource(response.records);
        } 
        else {
          this.dataSourceYearlyStatistics =new MatTableDataSource<YearlyStatistics>([]);
        }
      } 
      else{
        const response = await this.restService.getStatisticsByYear(5, year);
        response.subscribe(data => {
          if (data && data.records && Array.isArray(data.records)) {
          this.yearlyStatistics = data.records;
          this.setColumns(this.yearlyStatistics);
          this.dataSourceYearlyStatistics = new MatTableDataSource(this.yearlyStatistics);
        } 
        else {
          this.dataSourceYearlyStatistics =new MatTableDataSource<YearlyStatistics>([]);
        }  
        this.isLoading = false;
        this.cdr.detectChanges(); 
      }, error => {
        this.dataSourceYearlyStatistics = new MatTableDataSource<YearlyStatistics>([]);
        this.isLoading = false;
        this.cdr.detectChanges(); 
      });
    }
    }
    catch (error) {
      console.error('Error fetching paged statistics:', error); 
    } 
    finally {
      this.isLoading = false;
      this.setColumns(this.yearlyStatistics);
    }
  }

  async onPageSizeChanged(){
    localStorage.setItem('pageSize', this.paginator.pageSize.toString());//this.paginator.pageSize.toString());
  }

  sortData(sort: Sort) {
    localStorage.setItem('reportSortColumn', sort.active);
    localStorage.setItem('reportSortDirection', sort.direction);
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction;
  }

  }

  // if (!Array.isArray(this.monthsYears) || this.monthsYears.length <= 0) {
    //   const observable = await this.restService.getEOMClaimReport(5);
    //   observable.subscribe(data => {
    //     this.monthsYears = this.functionsService.getUniqueMonthYearDates(data.map(y => y.createDate));
    //   });
    //   if (!this.monthsYears.some(x=>x.getFullYear()==this.today.getFullYear() && x.getMonth()==this.today.getMonth())){
    //     this.monthsYears.push(this.currentMonth);
    //     this.selectedMonthsYears = this.currentMonth;
    //     this.months.setValue(this.datepipe.transform( this.selectedMonthsYears, 'MMMM yyyy'));
    //   }
    //   this.months.setValue(this.datepipe.transform( this.selectedMonthsYears, 'MMMM yyyy'));
    // }
    // else if (!this.monthsYears.some(x=>x.getFullYear()==this.today.getFullYear() && x.getMonth()==this.today.getMonth())){
    //   this.monthsYears.push(this.currentMonth);
    //   this.selectedMonthsYears = this.currentMonth; 
    //   this.months.setValue(this.datepipe.transform( this.selectedMonthsYears, 'MMMM yyyy'));    
    // }
    // this.months.setValue(this.datepipe.transform( this.selectedMonthsYears, 'MMMM yyyy'));